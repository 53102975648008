import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import example_week_grid from '../../../../../../../common/src/assets/image/example_week_grid.png';
import template_options_week_grid from '../../../../../../../common/src/assets/image/template_options_week_grid.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        The Week Grid template generates a calendar showing 7 days arranged in a
        grid layout. Unlike the Week Column template style, this style does not
        show time slots. Each event or task will be ordered based on its
        starting time. All day events will be placed at the top of each day
        cell.
      </p>
      <p>
        <Image alt="" src={example_week_grid} style={{ width: 1103 }} />
      </p>
      <h2>Template Settings</h2>
      <p>
        The Week Grid template provides the following options when creating a
        calendar...
      </p>
      <Image alt="" src={template_options_week_grid} style={{ width: 448 }} />
      <br />
      <table className="table">
        <tbody>
          <tr>
            <td>
              <strong>Start date</strong>
            </td>
            <td>
              The start date can be typed into the box or selected using the
              drop-down arrow. Typically, the start date should correspond with
              the first day of the week, although any date can be used as the
              starting date..
            </td>
          </tr>
          <tr>
            <td>
              <strong>Number of weeks</strong>
            </td>
            <td>
              Multiple weeks can be generated. Each week will be placed on its
              own worksheet (Excel) or page (Word). You can select how many
              weeks to include in the output by clicking the drop-down arrow.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Text behavior</strong>
            </td>
            <td>
              Titles can be wrapped (if space allows), never wrapped, or shrunk
              to fit so that wrapping is unnecessary. You can pick the desired
              behavior via the drop-down list.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Time format</strong>
            </td>
            <td>
              The time format. Supported formats are <strong>12-hour</strong>{' '}
              (8:00 PM), <strong>24-hour</strong> (20:00),{' '}
              <strong>Shortened</strong> (8pm), <strong>Military </strong>
              (2000), and <strong>Numeric </strong>(20.0). An example time is
              shown for each format in the drop-down list.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Only use background color for all-day items</strong>
            </td>
            <td>
              When checked, only events or tasks that are all-day or span
              multiple days will have a background color. Other items will have
              their text colored without a background.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show start time </strong>
            </td>
            <td>
              When checked, event or task start times will be shown. The
              ordering of times, title, and location values can be specified in
              the <Link to="/Documentation/Options">PrintableCal options</Link>.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show end time</strong>
            </td>
            <td>When checked, event or task end times will be shown.</td>
          </tr>
          <tr>
            <td>
              <strong>Use bold text for item times</strong>
            </td>
            <td>
              <span>
                When checked, item times will be in bold text, to help
                differentiate from other values.
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show location</strong>
            </td>
            <td>When checked, event or task locations will be shown.</td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Templates-and-Options/Week-Grid"
      commentsId="commentsplus_post_129_489"
      title="Week Grid Template"
      description="The Week Grid template style generates a printable calendar showing 7 days arranged in a grid layout. Unlike the Week Column template style, this style does not show time slots."
      keywords="week template, printable week, week grid, Excel calendar, print week, generate week calendar"
      content={content}
    />
  );
};

export default Documentation;
